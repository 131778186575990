import React from "react";
import { Modal } from "react-bootstrap";

function AoC(props) {
  return (
    <div>
      <Modal
        show={props.show}
        fullscreen={true}
        onHide={() => props.setShow(false)}>
        <Modal.Header>
          <Modal.Title>Acknowledge of Country</Modal.Title>
          <button
            type="button"
            class="btn-close btn-close-white"
            onClick={() => props.setShow(false)}
            aria-label="Close"
          />
        </Modal.Header>
        <Modal.Body>
          <iframe
            width="100%"
            height="100%"
            src={props.video + "?autoplay=1"}
            title="Acknowledge of Country"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen></iframe>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default AoC;
