import React from "react";
import { Modal } from "react-bootstrap";

function CFMIntro(props) {
  return (
    <div>
      <Modal size="lg" show={props.show} onHide={() => props.setShow(false)}>
        <Modal.Header>
          <Modal.Title>Colonial Frontiers Massacre Map</Modal.Title>
          <button
            type="button"
            class="btn-close btn-close-white"
            onClick={() => props.setShow(false)}
            aria-label="Close"
          />
        </Modal.Header>
        <Modal.Body>
          <p>
            Please be advised, the Colonial Frontiers Massacre Map research data
            is an ongoing project. The information presented on this site drawn
            from this project and my own personal work in collaboration with Dr
            Ray Kerkove. The detail represents the best evidence available to
            the research team from the viewpoint of the victors. Please be
            advised, this site is subject to ongoing change based upon feedback,
            community consultation and further research - suggestions we invite.
          </p>

          <p>
            Their Research Team included: Lyndall Ryan; Jennifer Debenham; Bill
            Pascoe; Robyn Smith; Chris Owen; Jonathan Richards; Stephanie
            Gilbert, ; Robert J Anders; Usher; Daniel Price; Jack Newley; Mark
            Brown; Le Hoang; and Hedy Fairbairn. We have followed their terms of
            re-use and record that their project was funded by the Australian
            Research Council Project. Our representation is funded by Brett
            Leavy, a descendant of apical ancestor, Kitty of Bollon, who, along
            with a few other Kooma, were survivors of the Frontier Wars of south
            west Queensland.
          </p>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default CFMIntro;
