import './App.css';
import 'mapbox-gl/dist/mapbox-gl.css';
import React, { useEffect, useState, useCallback } from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Home from './components/home/Home';
import Projects from './components/projects/Projects';
import Editor from './components/editor/Editor';
import EditorMap from './components/editor/EditorMap';
import SignIn from './components/auth/SignIn';
import SignUp from './components/auth/SignUp';
import ProjectForm from './components/editor/ProjectForm';
import ProjectDetails from './components/projects/ProjectDetails';
import Cart from './components/payment/Cart';
import Checkout from './components/payment/Checkout';
import Terms from './components/payment/Terms';
import Project from './components/home/Sidebar/Project';
import About from './components/home/Sidebar/About';
import Sponsors from './components/home/Sidebar/Sponsors';
import Welcome from './components/home/Sidebar/Welcome';
import WelcomeDefault from './components/home/Sidebar/WelcomeDefault';
import Site from './components/home/Sidebar/Site';
import Intro from './components/home/Intro';
import Layout from './components/home/Layout';

import Amplify, { Auth } from 'aws-amplify';
import awsExports from './aws-exports';
import Button from '@mui/material/Button';  // Import the Button component

Amplify.configure(awsExports);

function App() {

  const [loggedIn, setLoggedIn] = useState(false);
  const [site, setSite] = useState();
  
  const assessLoggedInState = () => {
    Auth.currentAuthenticatedUser()
      .then(sess => {
        console.log('logged in');
        setLoggedIn(true);
      })
      .catch(() => {
        console.log('not logged in');
        setLoggedIn(false);
      });
  };

  useEffect(() => {
    assessLoggedInState();
  }, []);

  const signOut = async () => {
    try {
      await Auth.signOut();
      setLoggedIn(false);
    } catch (error) {
      console.log('error signing out: ', error);
    }
  };

  return (
    <BrowserRouter>
      <div className="App">
        <Routes>
          <Route path='intro' element={<Intro />} />
          <Route path="/" element={<Home loggedIn={loggedIn} signOut={signOut} setSite={setSite} />} >
            <Route path="welcome" element={<Welcome />} >
              <Route path='slider/:projectID' element={<Project />} />
              <Route path='site' element={<Site site={site} />} />
              <Route path='site/:projectID' element={<Project />} />
              <Route index element={<WelcomeDefault />} />
            </Route>
            <Route path="about" element={<About />} />
            <Route path="sponsors" element={<Sponsors />} />
          </Route>
          <Route path="/projects" element={<Projects loggedIn={loggedIn} signOut={signOut} />} />
          <Route path="/projects/:id" element={<ProjectDetails />} />
          <Route path="/editor" element={<Editor loggedIn={loggedIn} signOut={signOut} />} />
          <Route path="/editor/:title" element={<EditorMap loggedIn={loggedIn} signOut={signOut} />} />
          <Route path="/editor/project/:id" element={<ProjectForm loggedIn={loggedIn} signOut={signOut} />} />
          <Route path="signin" element={<SignIn onSignin={assessLoggedInState} />} />
          <Route path="signup" element={<SignUp />} />
          <Route path="terms" element={<Terms />} />
          <Route path="/cart" element={<Cart />} />
          <Route path="/checkout/:id" element={<Checkout />} />
          <Route path="/layout" element={<Layout />} />
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;