import React, { useState, useEffect, useContext } from "react";
import { Modal } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { ProjectContext } from "../context/projects";
import CFMIntro from "../modal/CFMIntro";
import QRCode from "qrcode.react"; // Import QRCode component
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlay, faPause } from "@fortawesome/free-solid-svg-icons"; // Import play and pause icons

function DescriptionModal(props) {
  const CLOUDFRONT_URL = 'https://dblk66x9sfys0.cloudfront.net/video/'; // CloudFront URL pointing to the /videos/ folder
  const DEFAULT_VIDEO = CLOUDFRONT_URL + 'Southeast_Awabakal_Awaba.mp4';
  const DEFAULT_AUDIO = 'https://virtualsonglines131311-dev.s3.ap-southeast-2.amazonaws.com/video/SpiritLoop.wav';

  const [displayTitle, setDisplayTitle] = useState();
  const [displayHTML, setDisplayHTML] = useState();
  const [cfmShow, setCfmShow] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false); // State to manage play/pause

  const cfmStyle = {
    textAlign: "center",
  };

  const dateStyle = {
    fontWeight: "bold",
  };
  
  useEffect(() => {
    const isCHSitesRegister = props.site?.type === "CHSitesRegister" || props.site?.type === "CFM";
    const isProject = props.site?.type === "Project";

    const siteContent = props.site.content;
    let videoName;
    let videoURL;
    let imageUrl;
    let isImage;
    let audioURL;
    let wordList;
    let qrURL;

    let heritageType;
    let date_end;
    let description;
    let narrative;

    let siteTitle;
    let siteHTML;

    if (isCHSitesRegister) {
      videoName = `${siteContent.video}.mp4`;
      videoURL = siteContent.video ? `${CLOUDFRONT_URL}${videoName}` : DEFAULT_VIDEO;
      imageUrl = siteContent.image; // Assuming image URL is in `siteContent.image`
      isImage = Boolean(imageUrl); // Check if it's an image
      audioURL = siteContent.Audio || DEFAULT_AUDIO;
      wordList = siteContent.heritageWords;
      heritageType = siteContent.heritageType;
      date_end = siteContent.Date_End;
      description = siteContent.description;
      narrative = siteContent.Narrative;

      siteTitle = (<span>{siteContent.Site_Name || siteContent.heritageType}</span>);
      qrURL = siteContent.URL;

    } else if (isProject) {
      videoName = `${siteContent.video}.mp4`;
      videoURL = siteContent.video ? `${CLOUDFRONT_URL}${videoName}` : DEFAULT_VIDEO;
      wordList = siteContent.wordList;
      siteTitle = (<span>{siteContent.place_name}</span>);
      qrURL = "https://virtualsonglines.org/";
      description = siteContent.description || "";
      narrative = siteContent.Narrative;
    }

    siteHTML = (
      <div style={{ position: "relative" }}>
        {(isCHSitesRegister && date_end)  && (
          <div style={dateStyle}>
            <h3 className="amatic-title">
              {new Date(siteContent.Date_End).toUTCString().slice(5, 16)}
            </h3>
          </div>
        )}

        <div>
          {(isCHSitesRegister && isImage) ? (
            <img
              src={imageUrl}
              alt={heritageType}
              style={{ width: "100%" }}
            />
          ) : (
            <video
              autoPlay
              loop
              muted
              src={videoURL}
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = videoURL;
              }}
              type="video/mp4"
              alt={isCHSitesRegister ? heritageType : siteTitle}
              style={{ width: "100%" }}
            />
          )}

          <div
            style={{
              position: "absolute",
              bottom: "4vh",
              left: "2vh",
              width: "138px",
              height: "138px",
              backgroundColor: "white",
              opacity: 0.7,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "4px",
              boxSizing: "border-box",
            }}
          >
            <QRCode 
              value={qrURL} 
              size={128}
            />
          </div>
        </div>

        {(narrative || description) && (
          <p
            style={{
              position: window.innerWidth < 768 ? 'static' : 'absolute',
              bottom: window.innerWidth < 768 ? 'auto' : '2vh',
              right: window.innerWidth < 768 ? 'auto' : '2vh',
              top: 'auto',
              width: window.innerWidth < 768 ? '100%' : '50vw',
              maxWidth: '50vw',
              backgroundColor: "rgba(255, 255, 255, 0.1)",
              padding: "10px",
              borderRadius: "5px",
              fontSize: window.innerWidth < 768 ? '14px' : '18px',
              textAlign: window.innerWidth < 768 ? 'center' : 'left',
              marginTop: window.innerWidth < 768 ? '10px' : '0',
            }}
          >
            {narrative || description}
          </p>
        )}

        {wordList && (
          <div className="scroll-container">
            <div className="scroll-title">Heritage Vocabulary:</div>
            <div className="scroll-text amatic-title-reverse">
              {wordList}
            </div>
          </div>
        )}

        {(isCHSitesRegister && narrative) && (
          <div style={cfmStyle}>
            <Button variant="link" onClick={() => setCfmShow(true)}>
              About Colonial Frontiers Massacre Map
            </Button>
            <CFMIntro show={cfmShow} setShow={setCfmShow} />
          </div>
        )}
      </div>
    );

    setDisplayTitle(siteTitle);      
    setDisplayHTML(siteHTML);


  }, [props.site, cfmShow]);

  const togglePlayPause = () => {
    const audioElement = document.getElementById('audio_tag');
    if (isPlaying) {
      audioElement.pause();
    } else {
      audioElement.play();
    }
    setIsPlaying(!isPlaying);
  };

  return (
    <div>
      <Modal
        size="lg"
        show={props.show}
        onHide={() => props.setShow(false)}
        className="blurred modal">
        <Modal.Header>
          <Modal.Title>{displayTitle}</Modal.Title>
          <div style={{ marginLeft: 'auto', display: 'flex', alignItems: 'center' }}>
            <Button 
              variant="secondary" 
              onClick={() => {
                window.location.href = 'https://vs360maiwar.s3.ap-southeast-2.amazonaws.com/video/Campfire.apk';
              }} 
              style={{ marginRight: '10px' }}
            >
              Download Game Client
            </Button>
            <Button 
              variant="secondary" 
              onClick={togglePlayPause}
              style={{ marginRight: '10px' }}
            >
              <FontAwesomeIcon icon={isPlaying ? faPause : faPlay} />
            </Button>
            <div style={{
              display: "none",
            }}>
              <audio id="audio_tag">
                <source src={props.site?.content?.Audio || DEFAULT_AUDIO} type="audio/wav" />
                Your browser does not support the audio element.
              </audio>
            </div>
            <button
              type="button"
              className="btn-close btn-close-white"
              onClick={() => props.setShow(false)}
              aria-label="Close"
              style={{ marginLeft: '10px' }}
            />
          </div>
        </Modal.Header>
        <Modal.Body>
          {displayHTML}
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default DescriptionModal;