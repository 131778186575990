import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import MobileBottomNav from "../mobile/MobileBottomNav";
import MobileDrawerContent from "../mobile/MobileDrawerContent";
import DescriptionModal from "./DescriptionModal";
import Button from '@mui/material/Button';  // Import the Button component

import React, { useRef, useEffect, useState, useCallback } from "react";
import "mapbox-gl/dist/mapbox-gl.css";
import mapboxgl from "mapbox-gl";
import Sidebar from "./Sidebar/Sidebar";
import VSLogo from "./VSLogo";
import Timeline from "../timeline/Timeline";
import { useNavigate } from "react-router-dom";

import Banner from "./Banner";

// prettier-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;
mapboxgl.accessToken = "pk.eyJ1IjoiZ3VuZXJpYm9pIiwiYSI6ImNrMnM0NjJ1dzB3cHAzbXVpaXhrdGd1YjIifQ.1TmNd7MjX3AhHdXprT4Wjg";
const MAPSTYLE = "mapbox://styles/guneriboi/cl64e2bdx000e14qjra95pzns";

// Starting Camera
const LNG = 133.6568;
const LAT = -29.681;
const ZOOM = 3;

const drawerWidth = `25%`;

// Add CSS styles for the button and banner
const buttonStyle = {
  position: 'fixed',
  left: '20px',
  zIndex: 2000,  // Make sure it's above other elements
};

function Layout(props) {
  const [mobileOpen, setMobileOpen] = useState(false);
  const [mobileBottomNav, setMobileBottomNav] = useState();
  const map = useRef(null);
  const mapContainer = useRef(null);

  const [lng, setLng] = useState(LNG);
  const [lat, setLat] = useState(LAT);
  const [zoom, setZoom] = useState(ZOOM);
  const [timelineDisplay, setTimelineDisplay] = useState(false);

  const [project, setProject] = useState({});
  const [itemClicked, setItemClicked] = useState("");
  const [satellite, setSatellite] = useState(true);

  const [sideTabKey, setSideTabKey] = useState("about");
  const navigate = useNavigate();

  const [description, showDescription] = useState(false);
  const [descriptionData, setDescriptionData] = useState({});

  const [isBannerVisible, setIsBannerVisible] = useState(false);  // Manage banner visibility
  
  // Function to toggle banner visibility
  const toggleBanner = useCallback(() => {
    setIsBannerVisible(prevState => !prevState);
  }, []);

  useEffect(() => {
    if (map.current) return; // initialize map only once
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: MAPSTYLE,
      center: [lng, lat],
      zoom: zoom,
      minZoom: 3,
      maxZoom: 15,
    });

    function handleCamera(camera) {
        if (!map.current) return;
        // console.log(camera);
        map.current.flyTo(camera);
    }

    map.current.on("click", (e) => {
      const layersToQuery = [
        "CHSitesRegister",
        "Projects",  // Ensure Projects layer is part of the query
        "projects",
        "colonialfrontiermassacres",
        "FrontierWars",
      ].filter((layer) => map.current.getLayer(layer));
      
      var location = map.current.queryRenderedFeatures(e.point, {
        layers: layersToQuery,
      });

      if (location.length !== 0) {
        if (location[0].layer.id === "Projects") {
          const defaultProjectData = {
            type: "Project",
            content: {
              place_name: "Unknown Project",
              description: "No description available.",
              URL: "https://virtualsonglines.org", // Default URL for the QR code
              video: "defaultVideo", // Placeholder for default video
              heritageType: "Unknown Heritage",
              Date_End: new Date().toISOString(), // Set a default date
            },
          };
        
          const projectData = location[0].properties || defaultProjectData.content;
          
          setDescriptionData({
            type: "Project",
            content: projectData,
          });
          showDescription(true);
        }
        // Other conditions for different layers (e.g., CHSitesRegister, colonialfrontiermassacres)
        else if (location[0].layer.id === "CHSitesRegister") {
          setSideTabKey("welcome");
          setDescriptionData({
            type: "CHSitesRegister",
            content: location[0].properties,
          });
          showDescription(true);
        } else if (location[0].layer.id === "colonialfrontiermassacres") {
          setDescriptionData({ type: "CFM", content: location[0].properties });
          handleCamera({
            center: [e.lngLat.lng, e.lngLat.lat],
            zoom: 10,
          });
          showDescription(true);
        } else if (location[0].layer.id === "FrontierWars") {
          setDescriptionData({ type: "CFM", content: location[0].properties });
          handleCamera({
            center: [e.lngLat.lng, e.lngLat.lat],
            zoom: 10,
          });
          setSideTabKey("welcome");
        }
      } else {
        navigate("welcome");
      }
    });
  }, [props, description, descriptionData]);

  useEffect(() => {
    if (!map.current) return; // wait for map to initialize
    map.current.on("move", () => {
      setLng(map.current.getCenter().lng.toFixed(4));
      setLat(map.current.getCenter().lat.toFixed(4));
      setZoom(map.current.getZoom().toFixed(2));
      if (zoom > 6.5 && zoom < 11) {
        setTimelineDisplay(false);
      } else {
        setTimelineDisplay(false);
      }
    });
  });

  function handleCamera(camera) {
    if (!map.current) return;
    map.current.flyTo(camera);
  }

  function handleSlider(event, value) {
    const filters = ["==", ["get", "Time_Slot"], value];
    map.current.setFilter("colonialfrontiermassacres", filters);
  }

  useEffect(() => {
    satellite
      ? map.current.setStyle(MAPSTYLE)
      : map.current.setStyle(
          "mapbox://styles/guneriboi/cl57v3imy000915p862fsupba"
        );
  }, [satellite]);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <Box
      sx={{
        color: "white",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundColor: "rgba(0,0,0,0.9)",
        overflow: "scroll",
        height: "100%",
        zIndex: "0",
      }}>
      <Sidebar
        project={project}
        itemClicked={itemClicked}
        sideTabKey={sideTabKey}
        setSideTabKey={setSideTabKey}
      />
    </Box>
  );

  return (
    <>
      <Box sx={{ display: "flex" }}>
        <Box
          component="main"
          sx={{
            position: "fixed",
            top: { xs: "auto", sm: 0 },
            bottom: { xs: 0, sm: "auto" },
            left: 0,
            p: 0,
            m: 0,
            width: { xs: "100vw", sm: `calc(100% - ${drawerWidth})` },
            height: { xs: `calc(100%)`, xl: `calc(100%)` },
          }}
          ref={mapContainer}>
          <Box
            sx={{
              backgroundColor: "rgba(35, 55, 75, 0.9)",
              color: "#fff",
              padding: "6px 12px",
              fontFamily: "monospace",
              zIndex: 1,
              position: "absolute",
              bottom: "3em",
              right: "1em",
              borderRadius: "4px",
              display: { xs: "none", sm: "block" },
            }}>
            Longitude: {lng} | Latitude: {lat} | Zoom: {zoom}
          </Box>

          {timelineDisplay ? (
            <Timeline handleSlider={handleSlider} />
          ) : (
            <div></div>
          )}

          <Box
            sx={{
              position: "absolute",
              top: { xs: "4em", sm: "1em" },
              zIndex: "1",
              left: "1em",
            }}>
            <VSLogo
              handleCamera={handleCamera}
              lng={LNG}
              lat={LAT}
              zoom={ZOOM}
              setSideTabKey={setSideTabKey}
            />
          </Box>
        </Box>

        <Box
          sx={{
            width: "100%",
            position: "absolute",
            bottom: 0,
            display: { xs: "block", sm: "none" },
          }}>
          <MobileBottomNav
            mobileBottomNav={mobileBottomNav}
            setMobileBottomNav={setMobileBottomNav}
            handleDrawerToggle={handleDrawerToggle}
          />
        </Box>

        <Box
          component="nav"
          sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
          aria-label="mailbox folders">
          <Drawer
            variant="temporary"
            anchor="bottom"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true,
            }}
            sx={{
              display: { xs: "block", sm: "none" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: "100%",
                height: "40%",
              },
            }}>
            <Box
              sx={{
                color: "white",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                backgroundColor: "rgba(0,0,0,0.8)",
                overflow: "scroll",
                height: "100%",
                padding: "10%",
                textAlign: "left",
              }}>
              <MobileDrawerContent mobileBottomNav={mobileBottomNav} />
            </Box>
          </Drawer>

          <Drawer
            variant="permanent"
            anchor="right"
            sx={{
              display: { xs: "none", sm: "block" },
              p: 0,
              m: 0,
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
                borderLeft: 0,
              },
            }}
            open>
            {drawer}
          </Drawer>
        </Box>

        <DescriptionModal
          show={description}
          setShow={showDescription}
          site={descriptionData}
        />
      </Box>
      {/* Projects Button to Toggle the Banner */}
      <Button
          variant="contained"
          onClick={toggleBanner}
          style={{ ...buttonStyle, bottom: isBannerVisible ? '140px' : '20px' }}  // Conditionally adjust the bottom property
        >
          {isBannerVisible ? 'Hide Projects' : 'Show Projects'}
        </Button>

        {/* Conditionally Render the Banner */}
        {isBannerVisible && (
          <Banner
            map={map} 
            mapContainer={mapContainer}
            setDescriptionData={setDescriptionData}
            showDescription={showDescription}
          />
        )}
    </>
  );
}

export default Layout;