import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Container, Row, Col, Button, Form, Modal } from "react-bootstrap";
// import { AmplifyS3Image } from '@aws-amplify/ui-react/legacy';

import { API, Storage } from "aws-amplify";
import { getProject } from "../../graphql/queries";
import { updateProject } from "../../graphql/mutations";
// import Video360 from '../modal/Video360';
// import AoC from '../modal/AoC';
import { v4 as uuid } from "uuid";

function ProjectForm(props) {
  let { id } = useParams();
  const [project, setProject] = useState(null);
  // const [fullscreen, setFullscreen] = useState(true);
  // const [show360, setShow360] = useState(false);
  // const [aocShow, setAocShow] = useState(false);
  const [progressShow, setProgressShow] = useState(false);
  const [progress, setProgress] = useState("Uploading");

  const [imgData, setImgData] = useState();
  const [mapData, setMapData] = useState();
  const [screenshotsData, setScreenshotsData] = useState();
  const [videoData, setVideoData] = useState();

  // const [video360AccessURL, setVideo360AccessURL] = useState();

  useEffect(() => {
    const fetchProject = async () => {
      try {
        const projectData = await API.graphql({
          query: getProject,
          variables: { id },
        });
        const project = projectData.data.getProject;
        const imgAccessURL = await Storage.get(project.image);
        const videoAccessURL = await Storage.get(project.video);
        // console.log(project)
        const newProject = {
          ...project,
          imgAccessURL: imgAccessURL,
          videoAccessURL: videoAccessURL,
        };
        setProject(newProject);
        console.log(newProject);

        // const imageAccessURL = await Storage.get(project.image);
        // setImgAccessURL(imageAccessURL)
      } catch (error) {
        console.log("error on fetching projects", error);
      }
    };

    fetchProject();
  }, [id]);

  // async function handleShow() {
  //     const videoAccessURL = await Storage.get(project.video360);
  //     setVideo360AccessURL(videoAccessURL)
  //     setFullscreen(true);
  //     setShow360(true);
  // }

  // function handleAoCShow() {
  //     setAocShow(true)
  // }

  function handleProgressShow() {
    setProgressShow(true);
  }

  function handleProgressClose() {
    setProgressShow(false);
  }

  const uploadProject = async (e) => {
    e.preventDefault();

    const updateInput = {
      id: project.id,
      title: project.title,
      price: project.price,
      aocVideo: project.aocVideo,
      gameLink: project.gameLink,
      description: project.description,
      _version: project._version,
    };

    if (videoData !== null && videoData !== undefined) {
      const videoType = videoData.type.split("/")[1];
      const videoName = uuid() + "." + videoType;

      const { key } = await Storage.put(videoName, videoData, {
        level: "public",
        contentType: videoType,
      });

      updateInput.video = key;
    }

    if (imgData !== null && imgData !== undefined) {
      const imgType = imgData.type.split("/")[1];
      const imgName = uuid() + "." + imgType;

      // const { key } = await Storage.put(imgName, imgData, {
      //     level: "public",
      //     contentType: imgType,
      // });

      await Storage.put(imgName, imgData, {
        level: "public",
        contentType: imgType,
      }).then((result) => {
        updateInput.image = result.key;
      });

      // console.log(key)

      // setProject({ ...project, newImage: key })
    }

    if (mapData !== null && mapData !== undefined) {
      const mapType = mapData.type.split("/")[1];
      const mapName = mapData.name;

      await Storage.put(mapName, mapData, {
        level: "public",
        contentType: mapType,
      }).then((result) => {
        updateInput.map = result.key;
      });
    }

    if (screenshotsData !== null && screenshotsData !== undefined) {
      updateInput.screenshots = [];

      for (let i = 0; i < screenshotsData.length; i++) {
        const screenshotType = screenshotsData[i].type.split("/")[1];
        const screenshotName = uuid() + "." + screenshotType;

        await Storage.put(screenshotName, screenshotsData[i], {
          level: "public",
          contentType: screenshotType,
        }).then((result) => {
          updateInput.screenshots.push(result.key);
        });
      }
    }

    // if (video360Data !== null && video360Data !== undefined) {
    //     const video360Type = video360Data.type.split('/')[1]
    //     const video360Name = uuid() + '.' + video360Type

    //     const { key } = await Storage.put(video360Name, video360Data, {
    //         level: "public",
    //         contentType: video360Type,
    //     });

    //     updateInput.video360 = key
    // }

    console.log(updateInput);

    try {
      const result = await API.graphql({
        query: updateProject,
        variables: { input: updateInput },
      });
      setProgress("Project Updated");
      console.log(result);
      setImgData(null);
      setVideoData(null);
      // setVideo360Data(null)
      setScreenshotsData(null);
    } catch (error) {
      setProgress(`Error! ${error}`);
    }
  };

  // function getS3URL(filename) {
  //     const fileAccessURL = await Storage.get(filename, { expires: 60 });
  //     return fileAccessURL
  // }

  // function handleMapUpload(e) {
  //     console.log(e.target.files[0].name)
  // }

  return (
    <div>
      {!project ? (
        <h3>{id}</h3>
      ) : (
        <Container className="pt-4 mb-4">
          <Form onSubmit={uploadProject}>
            <Form.Group as={Row} className="mb-3" controlId="projectTitle">
              <Form.Label column sm="2">
                Title
              </Form.Label>
              <Col sm="5">
                <Form.Control
                  type="text"
                  value={project.title}
                  onChange={(e) =>
                    setProject({ ...project, title: e.target.value })
                  }
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-3" controlId="projectPrice">
              <Form.Label column sm="2">
                Price
              </Form.Label>
              <Col sm="5">
                <Form.Control
                  type="text"
                  value={project.price}
                  onChange={(e) =>
                    setProject({ ...project, price: e.target.value })
                  }
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-3" controlId="projectImage">
              <Form.Label column sm="2">
                Image
              </Form.Label>
              <Col sm="5">
                <Form.Control
                  type="file"
                  accept="image/*"
                  onChange={(e) => setImgData(e.target.files[0])}
                />
              </Col>
              <Col sm="3">
                <img src={project.imgAccessURL} alt={project.image} />
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-3" controlId="projectImage">
              <Form.Label column sm="2">
                Map
              </Form.Label>
              <Col sm="5">
                <Form.Control
                  type="file"
                  accept="image/*"
                  onChange={(e) => setMapData(e.target.files[0])}
                />
              </Col>
              <Col sm="3">
                <p>{project.map}</p>
              </Col>
            </Form.Group>

            <Form.Group
              as={Row}
              className="mb-3"
              controlId="projectScreenshots">
              <Form.Label column sm="2">
                Screenshots
              </Form.Label>
              <Col sm="5">
                <Form.Control
                  type="file"
                  multiple
                  accept="image/*"
                  onChange={(e) => setScreenshotsData(e.target.files)}
                />
              </Col>
            </Form.Group>

            <Form.Group
              as={Row}
              className="mb-3"
              controlId="projectVideoSnippet">
              <Form.Label column sm="2">
                Video Snippet
              </Form.Label>
              <Col sm="5">
                <Form.Control
                  type="file"
                  accept="video/*"
                  onChange={(e) => setVideoData(e.target.files[0])}
                />
              </Col>
              <Col sm="3">
                <video autoPlay loop muted src={project.videoAccessURL} />
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-3" controlId="project360Video">
              <Form.Label column sm="2">
                360 Video
              </Form.Label>
              <Col sm="5">
                <Form.Control
                  type="text"
                  placeholder={project.video360}
                  value={project.video360}
                  onChange={(e) =>
                    setProject({ ...project, video360: e.target.value })
                  }
                />
                <Form.Text className="text-muted">Embeded video link</Form.Text>
              </Col>
              {/* <Col sm="3">
                                <Button className="me-2 btn btn-secondary" onClick={handleShow}>
                                    360 Video
                                </Button>
                                <Video360 video360AccessURL={video360AccessURL} show={show360} fullscreen={fullscreen} setShow={setShow360} title={project.title} />
                            </Col> */}
            </Form.Group>

            <Form.Group as={Row} className="mb-3" controlId="projectAoCVideo">
              <Form.Label column sm="2">
                AoC Video
              </Form.Label>
              <Col sm="5">
                <Form.Control
                  type="text"
                  placeholder={project.aocVideo}
                  value={project.aocVideo}
                  onChange={(e) =>
                    setProject({ ...project, aocVideo: e.target.value })
                  }
                />
                <Form.Text className="text-muted">Embeded video link</Form.Text>
              </Col>
              {/* <Col sm="3">
                                <Button className="me-2 btn btn-secondary" onClick={handleAoCShow}>
                                    Acknowledge of Country
                                </Button>
                                <AoC show={aocShow} setShow={setAocShow} aocVideo={project.aocVideo} />
                            </Col> */}
            </Form.Group>

            <Form.Group as={Row} className="mb-3" controlId="projectGameClient">
              <Form.Label column sm="2">
                Game Client
              </Form.Label>
              <Col sm="5">
                <Form.Control
                  type="text"
                  value={project.gameLink}
                  onChange={(e) =>
                    setProject({ ...project, gameLink: e.target.value })
                  }
                />
                <Form.Text className="text-muted">
                  Built file uploaded in AWS S3 bucket
                </Form.Text>
              </Col>
            </Form.Group>

            <Form.Group
              as={Row}
              className="mb-3"
              controlId="projectDescription">
              <Form.Label column sm="2">
                Description
              </Form.Label>
              <Col sm="5">
                <Form.Control
                  as="textarea"
                  rows={8}
                  value={project.description}
                  onChange={(e) =>
                    setProject({ ...project, description: e.target.value })
                  }
                />
              </Col>
            </Form.Group>

            <Button
              variant="primary"
              type="submit"
              onClick={handleProgressShow}>
              Submit
            </Button>
          </Form>

          <Modal show={progressShow} onHide={handleProgressClose}>
            <Modal.Header>
              <Modal.Title>Upload Progress</Modal.Title>
              <button
                type="button"
                class="btn-close btn-close-white"
                onClick={() => props.setShow(false)}
                aria-label="Close"
              />
            </Modal.Header>
            <Modal.Body>{progress}</Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" href="/editor">
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        </Container>
      )}
    </div>
  );
}

export default ProjectForm;
