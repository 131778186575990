import React from "react";
import { Modal } from "react-bootstrap";

function MapImage(props) {
  return (
    <div>
      <Modal
        show={props.show}
        fullscreen={true}
        onHide={() => props.setShow(false)}>
        <Modal.Header>
          <Modal.Title>Map</Modal.Title>
          <button
            type="button"
            class="btn-close btn-close-white"
            onClick={() => props.setShow(false)}
            aria-label="Close"
          />
        </Modal.Header>
        <Modal.Body>
          <img
            src={"https://dblk66x9sfys0.cloudfront.net/public/" + props.map}
            alt="Map"
          />
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default MapImage;
