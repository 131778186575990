
import React from "react";
import { Modal } from "react-bootstrap";
import { View } from "@aws-amplify/ui-react";
import "./ModalStyle.css";

function Privacy(props) {
  return (
    <div>
      <Modal
        size="lg"
        show={props.show}
        onHide={() => props.setShow(false)}
        className="blurred modal"
        dialogClassName="modal-dialog">
        <Modal.Header>
          <Modal.Title>Privacy Policy</Modal.Title>
          <button
            type="button"
            class="btn-close btn-close-white"
            onClick={() => props.setShow(false)}
            aria-label="Close"
          />
        </Modal.Header>
        <Modal.Body className="px-4 d-flex justify-content-center">
          <View width={{ large: "1200px" }}>
            <p>
              This Privacy Policy describes our policies and procedures on the
              collection, use, and disclosure of your information when you use
              our Application and tells you about your privacy rights and how
              the law protects you.
            </p>

            <p>
              We use your personal data to provide and improve our Application.
              By using our Application, you agree to the collection and use of
              information in accordance with this Privacy Policy.
            </p>

            <h3>Interpretation and Definitions - Interpretation</h3>
            <p>
              The words of which the initial letter is capitalized have meanings
              defined under the following conditions. The following definitions
              shall have the same meaning regardless of whether they appear in
              the singular or in the plural.
            </p>

            <h3>Definitions - For the purposes of this Privacy Policy:</h3>

            <p>
              "You" means the individual accessing or using the Service, or the
              company, or other legal entity on behalf of which such individual
              is accessing or using the Service, as applicable.
            </p>

            <p>
              "Company" (referred to as either "the Company", "we", "us" or
              "our" in this agreement) refers to Bilbie Pty Ltd, 5 Daniel Place,
              Newmarket, QLD 4051.
            </p>

            <p>
              "Affiliate" means an entity that controls, is controlled by, or is
              under common control with a party, whereas "control" means
              ownership of 50% or more of the shares, equity interest, or other
              securities entitled to vote for the election of directors or other
              managing authority.
            </p>

            <p>
              "Account" means a unique account created for You to access our
              Service or parts of our Service.
            </p>

            <p>
              "Application" refers to Bilbie XR Labs Applications, accessible
              for download via the Google Play Store and Apple's Apps Store.
            </p>

            <p>"Country" refers to: Queensland, Australia</p>

            <p>
              "Service Provider" means any natural or legal person who processes
              the data on behalf of the Company. It refers to third-party
              companies or individuals employed by our Company who facilitate
              this Application, provides the Application on behalf of the
              Company, perform services related to the Application, or assist
              the Company in analyzing how the Application is used.
            </p>

            <p>
              "Third-party Social Media Service" refers to any website or any
              social network website through which a User can log in or create
              an account to use the Application.
            </p>

            <p>
              "Personal Data" is any information that relates to an identified
              or identifiable individual.
            </p>

            <p>
              "Usage Data" refers to data collected automatically, either
              generated by the use of the Application.
            </p>
          </View>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default Privacy;