import React from "react";
import { Modal } from "react-bootstrap";
import {
  Button,
  Flex,
  Text,
  View,
  useTheme,
  TextField,
  TextAreaField,
} from "@aws-amplify/ui-react";

import FacebookIcon from "@mui/icons-material/Facebook";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import IconButton from "@mui/material/IconButton";

import { API } from "aws-amplify";
import { createContact } from "../../graphql/mutations";

import "./ModalStyle.css";

function Contact(props) {
  const { tokens } = useTheme();

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    const name = e.target.name.value;
    const email = e.target.email.value;
    const phone = e.target.phone.value;
    const subject = e.target.subject.value;
    const message = e.target.message.value;

    await API.graphql({
      query: createContact,
      variables: {
        input: {
          name,
          email,
          phone,
          subject,
          message,
        },
      },
      authMode: "API_KEY",
    });

    props.setShow(false);
  };

  return (
    <div>
      <Modal
        size="lg"
        show={props.show}
        onHide={() => props.setShow(false)}
        className="blurred modal"
        dialogClassName="modal-dialog">
        <Modal.Header>
          <Modal.Title>Contact</Modal.Title>
          <button
            type="button"
            class="btn-close btn-close-white"
            onClick={() => props.setShow(false)}
            aria-label="Close"
          />
        </Modal.Header>
        <Modal.Body>
          {/* <Flex justifyContent="center" alignItems="center" height="100%">
                        <Card
                            padding={{ large: tokens.space.xxxl }}
                            variation="elevated"
                            borderRadius={tokens.radii.medium}
                            backgroundColor={tokens.colors.blue[90]}
                        > */}
          <Flex
            direction={{ base: "column", large: "row" }}
            justifyContent={{ large: "center" }}
            gap={tokens.space.xl}>
            <Flex
              direction={"column"}
              justifyContent="space-between"
              padding={tokens.space.medium}>
              <View>
                <Text>Bilbie Pty Ltd</Text>
                <Text>100% Aboriginal Owned</Text>
                <Text>Social Impact Enterprise</Text>
              </View>

              <View>
                <Text>Creative Industries Precinct</Text>
                <Text>Z3, 106, Musk Ave</Text>
                <Text>Brisbane, QLD, Australia</Text>
              </View>

              <View>
                <Text>brett@virtualsonglines.org</Text>
                <Text>+61 406 220 404</Text>
                <Flex>
                  <IconButton
                    href="https://www.facebook.com/virtualsonglines/"
                    target="_blank">
                    <FacebookIcon fontSize="large" />
                  </IconButton>

                  <IconButton
                    href="https://www.linkedin.com/in/brett-leavy-23609526/"
                    target="_blank">
                    <LinkedInIcon fontSize="large" />
                  </IconButton>

                  <IconButton
                    href="https://twitter.com/Guneriboi"
                    target="_blank">
                    <TwitterIcon fontSize="large" />
                  </IconButton>

                  <IconButton
                    href="https://www.instagram.com/virtualsonglines/"
                    target="_blank">
                    <InstagramIcon fontSize="large" />
                  </IconButton>
                </Flex>
              </View>
            </Flex>

            <View
              width={{ base: "70vw", large: "700px" }}
              padding={tokens.space.medium}
              className="bg-transparent-2"
              borderRadius="1rem">
              <Flex as="form" direction={"column"} onSubmit={handleFormSubmit}>
                <TextField
                  required
                  // label="Your Name"
                  name="name"
                  placeholder="Name"
                  style={textFieldStyle}
                />
                <TextField
                  // label="Email"
                  name="email"
                  placeholder="Email"
                  type={"email"}
                  required
                  style={textFieldStyle}
                />
                <TextField
                  // label="Email"
                  name="phone"
                  placeholder="Phone"
                  required
                  style={textFieldStyle}
                />
                <TextField
                  // label="Subject"
                  name="subject"
                  placeholder="Subject"
                  required
                  style={textFieldStyle}
                />
                <TextAreaField
                  required
                  // label="Message"
                  name="message"
                  placeholder="Enter your message"
                  style={textFieldStyle}
                />
                <View style={{ marginTop: tokens.space.medium }}>
                  <Button
                    type="submit"
                    variation="primary"
                    style={{ borderRadius: "0.7rem" }}>
                    Send Message
                  </Button>
                </View>
              </Flex>
            </View>
          </Flex>
          {/* </Card>
                    </Flex> */}
        </Modal.Body>
      </Modal>
    </div>
  );
}

const textFieldStyle = {
  background: "rgba(255, 255, 255, 0.85)",
  borderRadius: "0.7rem",
};

export default Contact;
