import React from "react";
import Modal from "react-bootstrap/Modal";
// import Button from 'react-bootstrap/Button';

import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "./CheckoutForm";

// import Form from 'react-bootstrap/Form';

const stripePromise = loadStripe("pk_test_4fQ5aieqWlrcMXvp7Xcd4juE");

function CheckoutModal(props) {
  const options = {
    // passing the client secret obtained from the server
    // clientSecret: '{{CLIENT_SECRET}}',
    appearance: {
      theme: "night",
    },
  };

  return (
    <div>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">Checkout</Modal.Title>
          <button
            type="button"
            class="btn-close btn-close-white"
            onClick={() => props.setShow(false)}
            aria-label="Close"
          />
        </Modal.Header>
        <Modal.Body>
          {/* <Form>
                        <Form.Group className="mb-3" controlId="formName">
                            <Form.Label>Name</Form.Label>
                            <Form.Control type="text" placeholder="Name" />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formAddress">
                            <Form.Label>Address</Form.Label>
                            <Form.Control type="text" placeholder="Address" />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formEmail">
                            <Form.Label>Email address</Form.Label>
                            <Form.Control type="email" placeholder="Enter email" />
                        </Form.Group>




                        <Form.Group className="mb-3" controlId="formBasicCheckbox">
                            <Form.Check type="checkbox" label="I have read and agree to the Terms & Conditions" />
                        </Form.Group>

                    </Form> */}

          <Elements stripe={stripePromise} options={options}>
            <CheckoutForm total={props.price} cartItem={props.cartItem} />
          </Elements>
        </Modal.Body>
        {/* <Modal.Footer>
                    <Button onClick={props.onHide}>Close</Button>
                </Modal.Footer> */}
      </Modal>
    </div>
  );
}

export default CheckoutModal;
