
import React from "react";
import { Modal } from "react-bootstrap";
import { View } from "@aws-amplify/ui-react";
import "./ModalStyle.css";

function AboutMenu(props) {
  return (
    <div>
      <Modal
        size="lg"
        show={props.show}
        onHide={() => props.setShow(false)}
        className="blurred modal"
        dialogClassName="modal-dialog">
        <Modal.Header>
          <Modal.Title>About</Modal.Title>
          <button
            type="button"
            class="btn-close btn-close-white"
            onClick={() => props.setShow(false)}
            aria-label="Close"
          />
        </Modal.Header>
        <Modal.Body className="px-4 d-flex justify-content-center">
          <View width={{ large: "1200px" }}>
            <p>
              About Billbe XR Labs
            </p>

            <p><strong>
                A Digital Bridge to the Past
                </strong>
                </p>
                <p>
                Through immersive 3D mapping and cutting-edge technology, we are building digital twins of historically significant sites where Australia's major cities and regional towns now stand. Step back in time and walk alongside the First Nations custodians who have cared for this land since time immemorial.
                </p>
                <p>
                <strong>Honouring Tradition, Inspiring Connection</strong>
                </p>

                <p>
                We celebrate the resilience and wisdom of Aboriginal and Torres Strait Islander peoples, acknowledging their deep spiritual connection to Country. Through each cultural placemaking projects, we hope to foster greater understanding, respect, and recognition of First Nations culture and heritage across Australia.
                </p>

                <p>
                Join us as we embark on a virtual journey of discovery, connecting the past with the present and inspiring a shared future.
                </p>

                <p>
                Bilbie XR Labs is directed and managed by Brett Leavy, a proud Kooma man with extensive experience in cultural heritage consulting and geospatial mapping. He has worked tirelessly in the First Nations space for over 30 years. Brett works day-to-day as the creative director with a focus on the design and development of each virtual heritage project. His mission is to produce immersive and interactive virtual heritage digital twins. His passion is to create multi-layered, photorealistic, immersive, and engaging virtual heritage landscapes, showcasing First Nations people and their cultural heritage across Australia.

                </p>

            
          </View>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default AboutMenu;