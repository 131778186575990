import React from "react";
import { Modal } from "react-bootstrap";
import SponsorsList from "../../footer/sponsors.json";
import { Button, Container, Row, Col, Card } from "react-bootstrap";

function Sponsors(props) {
  return (
    <div>
      <Modal
        size="lg"
        show={props.show}
        onHide={() => props.setShow(false)}
        className="blurred modal">
        <Modal.Header>
          <Modal.Title>Sponsors</Modal.Title>
          <button
            type="button"
            class="btn-close btn-close-white"
            onClick={() => props.setShow(false)}
            aria-label="Close"
          />
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Row
              xs={3}
              className="mb-4 bg-transparent-1"
              style={{ borderRadius: "1.5rem" }}>
              {SponsorsList.map((sponsor, idx) => (
                <Col key={idx} className="text-center">
                  <a href={sponsor.link} target="_blank" rel="noreferrer">
                    <img
                      style={supportLogo}
                      src={process.env.PUBLIC_URL + "/support/" + sponsor.logo}
                      alt={sponsor.title}
                    />
                  </a>
                </Col>
              ))}
            </Row>
            <Row>
              <Card bg="transparent">
                <Card.Img
                  variant="top"
                  src={process.env.PUBLIC_URL + "/img/sponsorUs.png"}
                  alt="sponsorUs"
                />
                <Card.Body className="text-center">
                  <Button
                    variant="secondary"
                    href="https://www.kickstarter.com/projects/118363348/778759370?ref=69le0p&token=8311c379">
                    Sponsor Us
                  </Button>
                </Card.Body>
              </Card>
            </Row>
          </Container>
        </Modal.Body>
      </Modal>
    </div>
  );
}

const supportLogo = {
  height: "3em",
  width: "auto",
  margin: "1em 0",
};

export default Sponsors;
