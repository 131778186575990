import React, { useState, useEffect } from 'react';
import Privacy from '../../modal/Privacy';
import Contact from '../../modal/Contact';
import Sponsors from './Sponsors';
import AboutMenu from '../../modal/AboutMenu';  // New import for AboutMenu
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';

import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import IconButton from '@mui/material/IconButton';

function About({ toggleBanner, isBannerVisible }) {  // Receive banner toggle functions
    const [privacy, setPrivacy] = useState(false);
    const [contact, setContact] = useState(false);
    const [sponsors, setSponsors] = useState(false);
    const [about, setAbout] = useState(false);  // New state for About section

    useEffect(() => {
        // You can handle modal state here if needed
    }, [sponsors, contact, privacy, about]);

    const handleOpenSponsors = () => {
        setContact(false);
        setSponsors(true);
        setPrivacy(false);
        setAbout(false);
    };

    const handleOpenContact = () => {
        setSponsors(false);
        setContact(true);
        setPrivacy(false);
        setAbout(false);
    };

    const handleOpenPrivacy = () => {
        setSponsors(false);
        setContact(false);
        setPrivacy(true);
        setAbout(false);
    };

    const handleOpenAbout = () => {
        setSponsors(false);
        setContact(false);
        setPrivacy(false);
        setAbout(true);  // Open About section
    };

    return (
        <div>
            <div className="pb-3">
                <a href="https://www.virtualsonglines.org/">
                    <img style={bilbieLogo} src={process.env.PUBLIC_URL + '/BilbieLabLogo.png'} alt='BilbieLogo' />
                </a>
            </div>

            <div>
                <p><strong>Welcome to INDIJIVERSE</strong></p>
                <p><strong>Journey through Virtual Songlines</strong></p>
                <p>
                    At INDIJIVERSE, a pioneering project by Bilbie XR Labs, we invite you to explore the rich tapestry 
                    of Indigenous culture and heritage across Australia...
                </p>
            </div>

            {/* Stack the buttons vertically */}
            <Stack spacing={2} justifyContent="center" alignItems="center" direction="column">
                <Button variant="contained" onClick={handleOpenAbout}>About</Button>  {/* New About button */}
                <AboutMenu show={about} setShow={setAbout} />

                <Button variant="contained" onClick={handleOpenContact}>Contact</Button>
                <Contact show={contact} setShow={setContact} />

                <Button variant="contained" onClick={handleOpenSponsors}>Sponsors</Button>
                <Sponsors show={sponsors} setShow={setSponsors} />

                <Button variant="contained" onClick={handleOpenPrivacy}>Privacy Policy</Button>
                <Privacy show={privacy} setShow={setPrivacy} />
            </Stack>

            {/* Social Media Icons */}
            <Stack direction="row" justifyContent="space-evenly" alignItems="center">
                <IconButton href="https://www.facebook.com/virtualsonglines/" target="_blank" style={socialMediaIcon}>
                    <FacebookIcon fontSize="large" />
                </IconButton>

                <IconButton href="https://www.linkedin.com/in/brett-leavy-23609526/" target="_blank" style={socialMediaIcon}>
                    <LinkedInIcon fontSize="large" />
                </IconButton>

                <IconButton href="https://twitter.com/Guneriboi" target="_blank" style={socialMediaIcon}>
                    <TwitterIcon fontSize="large" />
                </IconButton>

                <IconButton href="https://www.instagram.com/virtualsonglines/" target="_blank" style={socialMediaIcon}>
                    <InstagramIcon fontSize="large" />
                </IconButton>
            </Stack>
        </div>
    );
}

const bilbieLogo = {
    "width": "4em",
    "float": "left",
    "marginRight": "2em"
};

const socialMediaIcon = {
    "color": "white"
};

export default About;