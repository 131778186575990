import React, { useRef, useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import Projects from '../projects/projects.json';
import { Container, Row, Col, Button, Modal } from 'react-bootstrap';  // Importing necessary components
import SiteForm from './SiteForm';
import 'mapbox-gl/dist/mapbox-gl.css';
import mapboxgl from 'mapbox-gl';

// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;
mapboxgl.accessToken = 'pk.eyJ1IjoiZ3VuZXJpYm9pIiwiYSI6ImNrMnM0YTlmejA2Nngzbm8zYmE0b29zc2gifQ.TXwvgDocFljyHybRpSAILA';

const MAPSTYLE = "mapbox://styles/guneriboi/ckvnrld4l0cb814plmn1878cz";

var currentMarkers = [];

function EditorMap() {
    let { title } = useParams();
    const project = Projects.find(project => project.title === title); // Find the project by title

    const map = useRef(null);
    const mapContainer = useRef(null);

    const [lngClicked, setLngClick] = useState(0);
    const [latClicked, setLatClick] = useState(0);
    const [showModal, setShowModal] = useState(false);  // State to control modal visibility
    const [modalContent, setModalContent] = useState({});  // State to hold modal content

    // Initialize or Reinitialize Map
    useEffect(() => {
        if (!project) return; // Do nothing if the project is not found

        // Remove existing map instance before creating a new one
        if (map.current) {
            map.current.remove();
        }

        // Initialize the map with the current project details
        map.current = new mapboxgl.Map({
            container: mapContainer.current,
            style: MAPSTYLE,
            center: project.camera.center,
            zoom: project.camera.zoom,
            minZoom: 3,
            maxZoom: 15,
        });

        map.current.addControl(new mapboxgl.NavigationControl(), 'bottom-left');
        map.current.getCanvas().style.cursor = 'crosshair';

        // Clean up markers when switching between projects
        currentMarkers = [];

        // Open modal with project layer information
        openModal(project);

    }, [project]);  // Reinitialize map and open modal when the project changes

    // Function to open the modal and display project information
    const openModal = (project) => {
        setModalContent({
            title: project.title,
            description: project.description,
            layers: project.layers // Assuming project has layers
        });
        setShowModal(true);  // Show the modal
    };

    // Function to handle modal close
    const handleCloseModal = () => {
        setShowModal(false);
    };

    // Handle click events to add markers
    useEffect(() => {
        if (!map.current) return; // Wait for map to initialize

        map.current.on('click', (e) => {
            setLngClick(e.lngLat.lng.toFixed(4));
            setLatClick(e.lngLat.lat.toFixed(4));

            // Clear old markers
            if (currentMarkers.length) {
                currentMarkers.forEach(marker => marker.remove());
            }

            // Add new marker at the clicked location
            const marker = new mapboxgl.Marker({
                color: "#FFFFFF",
                draggable: true
            }).setLngLat(e.lngLat).addTo(map.current);

            currentMarkers.push(marker);
        });

        // Clean up map click event on unmount
        return () => map.current && map.current.off('click');

    }, []);  // This effect only runs once when the component mounts

    return (
        <div>
            <Container fluid className="p-0">
                <Row style={containerStyle}>
                    <Col>
                        <div ref={mapContainer} style={mapStyle} />
                    </Col>
                    <Col xs lg="3" className="h-100 overflow-scroll">
                        <SiteForm lng={lngClicked} lat={latClicked} project={title} />
                    </Col>
                </Row>
            </Container>

            {/* Modal to display project information */}
            <Modal show={showModal} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>{modalContent.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>{modalContent.description}</p>
                    <h5>Layers:</h5>
                    <ul>
                        {modalContent.layers && modalContent.layers.map((layer, index) => (
                            <li key={index}>{layer}</li>  // Assuming layers are an array of strings
                        ))}
                    </ul>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseModal}>Close</Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

const containerStyle = {
    height: "100vh",
};

const mapStyle = {
    height: "100%",
    width: "100%",
};

export default EditorMap;