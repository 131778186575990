import { useEffect, useState } from "react";

const bannerStyle = {
	position: "fixed",
	bottom: 0,
	width: "100%",
	height: "15vh",
	backgroundColor: "rgba(0, 0, 0, 0.8)",
	color: "white",
	zIndex: 1000,
	display: "flex",
	justifyContent: "flex-start",
	alignItems: "center",
	textAlign: "center",
	padding: "0 10px",
	boxSizing: "border-box",
	overflowX: "auto",
};

const bannerContentStyle = {
	display: "flex",
	alignItems: "center",
	height: "100%",
	flexWrap: "nowrap",
};

const rectangleStyle = {
	width: "100px",
	aspectRatio: "16 / 9",
	margin: "0 10px",
	display: "flex",
	justifyContent: "center",
	alignItems: "center",
	borderRadius: "2px",
	overflow: "hidden",
	position: "relative",
	boxSizing: "border-box",
	height: "8vh",
	flexShrink: 0,
};

const imgStyle = {
	width: "100%",
	height: "100%",
	objectFit: "cover",
	zIndex: 1,
};

const overlayStyle = {
	position: "absolute",
	top: 0,
	left: 0,
	width: "100%",
	height: "100%",
	backgroundColor: "rgba(0, 0, 0, 0.4)",
	zIndex: 1,
};

const wordStyle = {
	position: "absolute",
	color: "white",
	fontSize: "1.5em",
	textAlign: "center",
	zIndex: 2,
};

function Banner({ map, mapContainer, setDescriptionData, showDescription }) {
  const imgSrc1 = "https://virtualsonglines131311-dev.s3.ap-southeast-2.amazonaws.com/public/325b7ee5-245b-4432-b628-e5fb78ccae08.png";
  const imgSrc2 = "https://virtualsonglines131311-dev.s3.ap-southeast-2.amazonaws.com/public/325b7ee5-245b-4432-b628-e5fb78ccae08.png";
  
  const [wordsArray, setWordsArray] = useState([]);
  const [projectsData, setProjectsData] = useState([]);

  useEffect(() => {
    const projectsData = map.current
			.querySourceFeatures('composite', { 'sourceLayer': "Projects"})
			.filter((project) => project.properties.place_name !== undefined);
    setProjectsData(projectsData);

    const projects = projectsData.map((project) => project.properties.place_name);
    setWordsArray(projects);
  }, [map, mapContainer]);

  const showProjectsModal = (selectedProjectName) => {
    const selectedProjectData = projectsData.find((project) => project.properties.place_name === selectedProjectName)?.properties;
    setDescriptionData({
      type: "Project",
      content: selectedProjectData
    });
    showDescription(true);
  } 

	return (
    <>
      <div style={bannerStyle}>
        <div style={bannerContentStyle}>
          {wordsArray.map((word, index) => {
            const imgSrc = index % 2 === 0 ? imgSrc1 : imgSrc2;
            return (
              <div 
                key={index} 
                style={rectangleStyle}
                onClick={() => showProjectsModal(word)}
              >
                {imgSrc ? (
                  <img src={imgSrc} alt={word} style={imgStyle} />
                ) : (
                  <span style={wordStyle}>
                    <h3>{word}</h3>
                  </span>
                )}
                <div style={overlayStyle}></div>
                <span style={wordStyle}>
                  <h3>{word}</h3>
                </span>
              </div>
            );
          })}
        </div>
      </div>
    </>
	);
}

export default Banner;
