import React, { useRef, useEffect, useState, useContext } from 'react';
import 'mapbox-gl/dist/mapbox-gl.css';
import mapboxgl from 'mapbox-gl';
import Sidebar from './Sidebar/Sidebar';
import ProjectSlider from '../projects/ProjectSlider';
import VSLogo from './VSLogo';
import { Container, Row, Col, Form } from 'react-bootstrap';
import Timeline from '../timeline/Timeline';
import Footer from '../footer/Footer';
import { useNavigate } from "react-router-dom";

import { ProjectContext } from "../context/projects";

// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

mapboxgl.accessToken =
    'pk.eyJ1IjoiZ3VuZXJpYm9pIiwiYSI6ImNrMnM0NjJ1dzB3cHAzbXVpaXhrdGd1YjIifQ.1TmNd7MjX3AhHdXprT4Wjg';

const MAPSTYLE = "mapbox://styles/guneriboi/cl64e2bdx000e14qjra95pzns"; //vs-homemap-satellite

// Starting Camera
const LNG = 133.6568;
const LAT = -29.6810;
const ZOOM = 3;

function Map(props) {
    const map = useRef(null);
    const mapContainer = useRef(null);

    const [lng, setLng] = useState(LNG);
    const [lat, setLat] = useState(LAT);
    const [zoom, setZoom] = useState(ZOOM);
    const [timelineDisplay, setTimelineDisplay] = useState(false);

    const [project, setProject] = useState({});
    const [itemClicked, setItemClicked] = useState("");
    const [satellite, setSatellite] = useState(true);

    const [sideTabKey, setSideTabKey] = useState('about');
    const navigate = useNavigate();
    const { projects } = useContext(ProjectContext);

    useEffect(() => {
        if (map.current) return; // initialize map only once
        map.current = new mapboxgl.Map({
            container: mapContainer.current,
            style: MAPSTYLE,
            center: [lng, lat],
            zoom: zoom,
            minZoom: 3,
            maxZoom: 15,
        });

        map.current.addControl(new mapboxgl.NavigationControl(), 'bottom-right');
        map.current.addControl(new mapboxgl.FullscreenControl());

        map.current.on('load', () => {
            map.current.addSource('mapbox-dem', {
                'type': 'raster-dem',
                'url': 'mapbox://mapbox.mapbox-terrain-dem-v1',
                'tileSize': 512,
                'maxzoom': 14
            });
            map.current.setTerrain({ 'source': 'mapbox-dem', 'exaggeration': 1.5 });

            map.current.addLayer({
                'id': 'sky',
                'type': 'sky',
                'paint': {
                    'sky-type': 'gradient',
                    'sky-gradient': [
                        'interpolate',
                        ['linear'],
                        ['sky-radial-progress'],
                        0.8,
                        'rgba(135, 206, 235, 1.0)',
                        1,
                        'rgba(0,0,0,0.1)'
                    ],
                    'sky-gradient-center': [0, 0],
                    'sky-gradient-radius': 90,
                    'sky-opacity': [
                        'interpolate',
                        ['exponential', 0.1],
                        ['zoom'],
                        5,
                        0,
                        22,
                        1
                    ]
                }
            });
        });

        map.current.on('click', (e) => {
            console.log('Inside effect Clicked');
            var location = map.current.queryRenderedFeatures(e.point, {
                layers: ["CHSitesRegister", "projects", "colonialfrontiermassacres"],
            });

            if (location.length !== 0) {
                console.log(location[0].layer.id);
                if (location[0].layer.id === 'projects') {
                    handleCamera({
                        center: [e.lngLat.lng, e.lngLat.lat],
                        zoom: 13,
                        pitch: 60
                    });
                    setSideTabKey('welcome');

                    props.setSite({type:'Project', content:location[0].properties});
                    navigate('welcome/site');
                } else if (location[0].layer.id === 'CHSitesRegister') {
                    setSideTabKey('welcome');
                    console.log('CHSitesRegister');
                    props.setSite({type:'CHSitesRegister', content:location[0].properties});
                    navigate('welcome/site');
                } else if (location[0].layer.id === 'colonialfrontiermassacres') {
                    props.setSite({type:'CFM', content:location[0].properties});
                    navigate('welcome/site');
                    console.log(location[0].properties);
                    handleCamera({
                        center: [e.lngLat.lng, e.lngLat.lat],
                        zoom: 10,
                    });
                    setSideTabKey('welcome');
                }
            } else {
                navigate('welcome');
            }
        });
    });

    useEffect(() => {
        if (!map.current) return; // wait for map to initialize
        map.current.on('move', () => {
            setLng(map.current.getCenter().lng.toFixed(4));
            setLat(map.current.getCenter().lat.toFixed(4));
            setZoom(map.current.getZoom().toFixed(2));
            if (zoom > 6.5 && zoom < 11) {
                setTimelineDisplay(true);
            } else {
                setTimelineDisplay(false);
            }
        });
    });

    function handleCamera(camera) {
        if (!map.current) return;
        map.current.flyTo(camera);
    }

    function handleSlider(event, value) {
        console.log(value);
        const filters = ['==', ['get', 'Time_Slot'], value];
        map.current.setFilter('colonialfrontiermassacres', filters);
    }

    useEffect(() => {
        satellite ? map.current.setStyle(MAPSTYLE) : map.current.setStyle('mapbox://styles/guneriboi/cl57v3imy000915p862fsupba');
    }, [satellite]);

    return (
        <div>
            <Container fluid style={containerStyle}>
                <Row className='h-100'>
                    <Col xs="8" lg="9" xxxl="10" className='d-flex flex-column'>
                        <Row className='flex-fill'>
                            <div ref={mapContainer} />
                        </Row>
                        {timelineDisplay ? (<Timeline handleSlider={handleSlider} />) : (<div></div>)}
                        <div style={infoStyle}>
                            Longitude: {lng} | Latitude: {lat} | Zoom: {zoom}
                        </div>
                        <Form style={satelliteSwicthStyle}>
                            <Form.Check
                                type="switch"
                                id="satellite-switch"
                                label={satellite ? "Satellite" : "Cultural"}
                                onChange={e => setSatellite(!e.target.checked)}
                            />
                        </Form>
                        <div style={vsLogo}>
                            <VSLogo handleCamera={handleCamera} lng={LNG} lat={LAT} zoom={ZOOM} setSideTabKey={setSideTabKey}/>
                        </div>
                    </Col>
                    <Col className="overflow-scroll p-0 h-100" style={sidebarStyle}>
                        <Sidebar project={project} itemClicked={itemClicked} sideTabKey={sideTabKey} setSideTabKey={setSideTabKey}/>
                    </Col>
                </Row>
            </Container>
            <div style={footerStyle}>
                <Container >
                    <Row>
                        <Col>
                            <ProjectSlider setProject={setProject} setItemClicked={setItemClicked} handleCamera={handleCamera} setSideTabKey={setSideTabKey} />
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    );
};

const vsLogo = {
    position: "absolute",
    top: "1em",
    zIndex: '1'
}

const containerStyle = {
    position: "fixed",
    top: "0",
    bottom: "5em",
}

const footerStyle = {
    height: "5em",
    position: "fixed",
    bottom: "0",
    width: "100%",
    backgroundImage: 'url(' + process.env.PUBLIC_URL + '/img/VS_indigenous-identity.jpg' + ')',
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
}

const sidebarStyle = {
    color: "white",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundColor: "#cccccc",
}

const infoStyle = {
    backgroundColor: "rgba(35, 55, 75, 0.9)",
    color: "#fff",
    padding: "6px 12px",
    fontFamily: "monospace",
    zIndex: "1",
    position: "absolute",
    bottom: "1em",
    left: "8em",
    borderRadius: "4px"
}

const satelliteSwicthStyle = {
    color: "#fff",
    position: "absolute",
    zIndex: "2",
    bottom: "1em",
    padding: "6px 12px",
    left: "0",
    borderRadius: "4px"
}

export default Map;